import React, { useState, useEffect } from 'react';
import ParallaxSections from './ParallaxSections';

const App = () => {


  const [config, setConfig] = useState(null);
  const epochTimeInSeconds = Math.floor(new Date().getTime() / 1000);
  useEffect(() => {
    fetch(`/config.json?${epochTimeInSeconds}`)
      .then(response => response.json())
      .then(data => {
          setConfig(data.main)
        
        //console.log(data['main'].icebreaker); // Process your data here
      })
      .catch(error => {
        console.error('Error fetching config.json:', error);
      });
  }, []);


  return (
    <div className="App">
    {config && <ParallaxSections config={config}/>}
    </div>
  );
};

export default App;
