import React, { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styles from './ParallaxSections.module.css';

gsap.registerPlugin(ScrollTrigger);

const ParallaxSections = ({ config }) => {
  const sectionsRef = useRef([]);
  const [imgSrcs, setImgSrcs] = useState({});



  useEffect(() => {
    const animationDots = document.querySelectorAll(`.${styles.dot}`);

    const updateImageSrcs = () => {
      animationDots.forEach((animationDot, index) => {
        const rect = animationDot.getBoundingClientRect();
        const parentRect = animationDot.parentElement.getBoundingClientRect();

        const top = (rect.top - parentRect.top) / parentRect.height;
        const left = (rect.left - parentRect.left) / parentRect.width;

        const sectionIndex = Math.floor(index / config.sections[0].content.length);
        const itemIndex = index % config.sections[0].content.length;
        const item = config.sections[sectionIndex].content[itemIndex];
        const meSrc = item.me || './anim/me.png';
        const mefSrc = item.mef || './anim/mef.png';
        const mebSrc = item.meb || './anim/meb.png';

        const keyframes = [
          { offset: 0, src: meSrc },
          { offset: 0.15, src: mefSrc },
          { offset: 0.75, src: mefSrc },
          { offset: 0.95, src: meSrc },
          { offset: 1, src: mebSrc },
        ];

        let newSrc;
        if (left <= 0.02) {
          newSrc = mebSrc;
          //console.log(`Dot ${index} is on the left side, changing src to ${mebSrc}`);
        } else {
          const keyframe = keyframes.find((kf, i) => {
            const nextKf = keyframes[i + 1] || { offset: 1 };
            return top >= kf.offset && top < nextKf.offset;
          });
          newSrc = keyframe ? keyframe.src : meSrc;
          if (keyframe) {
            //console.log(`Dot ${index}: Changing src to ${keyframe.src}`);
          }
        }

        if (newSrc) {
          setImgSrcs(prevState => ({
            ...prevState,
            [index]: newSrc,
          }));
        }

        //console.log(`Dot ${index}: top = ${top}, left = ${left}`);
      });

      requestAnimationFrame(updateImageSrcs);
    };

    requestAnimationFrame(updateImageSrcs);

    return () => {
      // Clean up the animation frame loop if necessary
    };
  }, [config]);

  useEffect(() => {
    // Animate the first section to its 0 position on page load
    if (sectionsRef.current[0]) {
      gsap.to(sectionsRef.current[0],
        {
          y: 0,
          opacity: 1,
          duration: 1.5,
          ease: 'power2.out',
        }
      );
    }

    sectionsRef.current.forEach((section, index) => {
      // Skip the first section since it is animated on page load
      if (index === 0) return;

      gsap.fromTo(section,
        { y: 500, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            toggleActions: "play none none reverse", // Reset when scrolling up
            markers: false,
          }
        }
      );
    });
  }, []);

  // Safely access content from each section in the main object
  const allSections = config?.sections || [];

  //console.log('All Sections:', config); // Debugging

  return (
    <div className={styles.portfolio}>
      <div className={styles.head}><div className={styles.opening} dangerouslySetInnerHTML={{ __html: config.opening }}></div></div>
      {allSections.map((section, sectionIndex) => (
        section.content.map((item, i) => (
          <div
            key={`${sectionIndex}-${i}`}
            className={`${styles.section} ${sectionIndex === 0 && i === 0 ? styles.firstSection : ''}`}
            ref={el => {
              sectionsRef.current[sectionIndex * section.content.length + i] = el;
            }}
          >
            {item.type === "video2" && (
              <div className={styles.inner}>
                <h1>{item.name}</h1>
                {item.blurb && (<p className={styles.blurb} dangerouslySetInnerHTML={{ __html: item.blurb }}></p>)}
                {item.role && (
                  <div className={styles['role-container']}>
                    <p className={styles.role} dangerouslySetInnerHTML={{ __html: item.role }}></p>
                    <div className={styles.dot}>
                      <img className={styles.me} src={imgSrcs[sectionIndex * section.content.length + i] || item.me} alt="moving dot" />
                    </div>
                  </div>
                )}
                {item.video && (<video className={styles.video} src={item.video} autoPlay muted loop playsInline controls></video>)}
                {item.blurb2 && (<p className={styles.blurb2} dangerouslySetInnerHTML={{ __html: item.blurb2 }}></p>)}
                {item.name2 && (<h2>{item.name2}</h2>)}
                {item.video2 && (<video className={styles.video} src={item.video2} autoPlay muted loop playsInline controls></video>)}
                {item.blurb3 && (<p className={styles.blurb2} dangerouslySetInnerHTML={{ __html: item.blurb3 }}></p>)}
              </div>
            )}

            {item.type === "upfront" && (
              <div className={styles.inner}>
                <h1>{item.name}</h1>
                {item.blurb && (<p className={styles.blurb} dangerouslySetInnerHTML={{ __html: item.blurb }}></p>)}
                {item.role && (
                  <div className={styles['role-container']}>
                    <p className={styles.role} dangerouslySetInnerHTML={{ __html: item.role }}></p>
                    <div className={styles.dot}>
                      <img className={styles.me} src={imgSrcs[sectionIndex * section.content.length + i] || item.me} alt="moving dot" />
                    </div>
                  </div>
                )}
                {item.img1 && (<img className={styles.img} src={item.img1} />)}
                {item.img2 && (<img className={styles.img} src={item.img2} />)}
                {item.caption && (<p className={styles.caption} dangerouslySetInnerHTML={{ __html: item.caption }}></p>)}
                {item.name2 && (<h2>{item.name2}</h2>)}
                {item.video && (<video className={styles.video} src={item.video} autoPlay muted loop playsInline controls></video>)}
                {item.blurb2 && (<p className={styles.blurb2} dangerouslySetInnerHTML={{ __html: item.blurb2 }}></p>)}
                {item.video2 && (<video className={styles.video} src={item.video2} autoPlay muted loop playsInline controls></video>)}
                {item.blurb3 && (<p className={styles.blurb2} dangerouslySetInnerHTML={{ __html: item.blurb3 }}></p>)}
              </div>
            )}

            {item.type === "basic" && (
              <div className={styles.inner}>
                <h1>{item.name}</h1>
                {item.blurb && (<p className={styles.blurb} dangerouslySetInnerHTML={{ __html: item.blurb }}></p>)}
                {item.role && (
                  <div className={styles['role-container']}>
                    <p className={styles.role} dangerouslySetInnerHTML={{ __html: item.role }}></p>
                    <div className={styles.dot}></div>
                  </div>
                )}
                {item.img1 && (<img className={styles.img} src={item.img1} />)}
                {item.img2 && (<img className={styles.img} src={item.img2} />)}
                {item.caption && (<p className={styles.caption} dangerouslySetInnerHTML={{ __html: item.caption }}></p>)}
                {item.name2 && (<h2>{item.name2}</h2>)}
                {item.video && (<video className={styles.video} src={item.video} autoPlay muted loop playsInline controls></video>)}
                {item.blurb2 && (<p className={styles.blurb2} dangerouslySetInnerHTML={{ __html: item.blurb2 }}></p>)}
                {item.video2 && (<video className={styles.video} src={item.video2} autoPlay muted loop playsInline controls></video>)}
                {item.blurb3 && (<p className={styles.blurb2} dangerouslySetInnerHTML={{ __html: item.blurb3 }}></p>)}
              </div>
            )}

            {item.type === "iframe" && (
              <div className={styles.inner}>
                <h1>{item.name}</h1>
                {item.blurb && (<p className={styles.blurb} dangerouslySetInnerHTML={{ __html: item.blurb }}></p>)}
                {item.role && (
                  <div className={styles['role-container']}>
                    <p className={styles.role} dangerouslySetInnerHTML={{ __html: item.role }}></p>
                    <div className={styles.dot}>
                      <img className={styles.me} src={imgSrcs[sectionIndex * section.content.length + i] || item.me} alt="moving dot" />
                    </div>
                  </div>
                )}
                {item.frame && (<iframe className={styles.frame} src={item.frame} seamless></iframe>)}
                {item.caption && (<p className={styles.caption} dangerouslySetInnerHTML={{ __html: item.caption }}></p>)}
              </div>
            )}

            {item.type === "video4" && (
              <div className={styles.inner}>
                <h1>{item.name}</h1>
                {item.blurb && (<p className={styles.blurb} dangerouslySetInnerHTML={{ __html: item.blurb }}></p>)}
                {item.img1 && (<img className={styles.img1} src={item.img1} />)}
                {item.role && (
                  <div className={styles['role-container']}>
                    <p className={styles.role} dangerouslySetInnerHTML={{ __html: item.role }}></p>
                    <div className={styles.dot}>
                      <img className={styles.me} src={imgSrcs[sectionIndex * section.content.length + i] || item.me} alt="moving dot" />
                    </div>
                  </div>
                )}
                {item.video && (<video className={styles.video4} src={item.video} autoPlay muted loop playsInline controls></video>)}
                {item.video && (<video className={styles.video4} src={item.video2} autoPlay muted loop playsInline controls></video>)}
                {item.video && (<video className={styles.video4b} src={item.video3} autoPlay muted loop playsInline controls></video>)}
                {item.video && (<video className={styles.video4c} src={item.video4} autoPlay muted loop playsInline controls></video>)}
                                </div>
            )}

            {item.type === "videoimg" && (
              <div className={styles.inner}>
                <h1>{item.name}</h1>
                {item.blurb && (<p className={styles.blurb} dangerouslySetInnerHTML={{ __html: item.blurb }}></p>)}
                {item.role && (
                  <div className={styles['role-container']}>
                    <p className={styles.role} dangerouslySetInnerHTML={{ __html: item.role }}></p>
                    <div className={styles.dot}>
                      <img className={styles.me} src={imgSrcs[sectionIndex * section.content.length + i] || item.me} alt="moving dot" />
                    </div>
                  </div>
                )}
                {item.video && (<video className={styles.video} src={item.video} autoPlay muted loop playsInline controls></video>)}
                {item.img1 && (<img className={styles.img} src={item.img1} />)}
                {item.caption && (<p className={styles.caption} dangerouslySetInnerHTML={{ __html: item.caption }}></p>)}

              </div>
            )}
          </div>
        ))
      ))}
    </div>
  );
};

export default ParallaxSections;
